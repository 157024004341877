import Vue from 'vue'
import Futures from '../Futures.vue'

require('../functions/bugsnag')

document.addEventListener('DOMContentLoaded', () => {
  // eslint-disable-next-line no-unused-vars
  const app = new Vue({
    el: '#futures',
    render: (h) => h(Futures),
  })
})
