import size from 'lodash/size'

export default {
  computed: {
    dates() {
      return Object.keys(this.allItems).sort()
    },
    theDay() {
      return this.dates[this.index]
    },
    size() {
      return size(this.allItems)
    },
    prevDay() {
      if (this.index === 0) {
        return undefined
      }
      return this.dates[this.index - 1]
    },
    nextDay() {
      if (this.index + 1 === this.size) {
        return undefined
      }
      return this.dates[this.index + 1]
    },
    theItems() {
      return this.allItems[this.theDay]
    },
    prevItems() {
      return this.allItems[this.prevDay]
    },
  },
  methods: {
    next() {
      this.index += 1
    },
    prev() {
      this.index -= 1
    },
  },
}
