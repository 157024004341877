<template>
  <div id="futures" class="Futures">
    <div class="contentHeading">
      <h2 class="contentHeading-title">電力取引情報</h2>
      <div
        is="ItemsByDay"
        :the-day="theDay"
        :prev-day="prevDay"
        :next-day="nextDay"
        @prev="prev"
        @next="next"
      />
    </div>

    <section class="tableSection">
      <div class="tableHeading">
        <h3 class="tableHeading-title">先物取引</h3>
        <p class="tableHeading-body t-cite">
          引用元:
          <cite
            ><a
              href="https://www.tocom.or.jp/jp/historical/download.html"
              target="_blank"
              rel="noopener"
              >株式会社東京商品取引所</a
            ></cite
          >
        </p>
      </div>

      <table class="otcTable">
        <thead class="otcTable-Head">
          <tr class="otcTable-row">
            <th class="otcTable-cell t-futuresName">原商品</th>
            <th class="otcTable-cell t-futuresMonth">限月</th>
            <th class="otcTable-cell t-futuresSettlementPrice">帳入値</th>
            <th class="otcTable-cell t-futuresVolume">売買高(枚)</th>
            <th class="otcTable-cell t-futureVolumeKwh">電力量換算取引量(kWh)</th>
          </tr>
        </thead>

        <tbody class="otcTable-Body">
          <tr
            v-for="item in displayingRows"
            :key="item.id"
            class="otcTable-row"
            :class="{ 't-none': !item.row }"
          >
            <td class="otcTable-cell t-futuresName" :class="item.class">
              <span class="otcTable-name t-pc">{{ item.pc_name }}</span>
              <span class="otcTable-name t-sp">{{ item.mobile_name }}</span>
            </td>
            <template v-if="item.row">
              <td class="otcTable-cell t-futuresMonth">
                {{ item.row.month | ym }}
              </td>
              <td class="otcTable-cell t-futuresSettlementPrice">
                {{ item.row.settlement_price | withDelimiter }}<span class="otcTable-unit">円</span>
              </td>
              <td class="otcTable-cell t-futuresVolume">
                {{ item.row.volume }}
              </td>
              <td class="otcTable-cell t-futureVolumeKwh">
                {{ item.row.volume_kwh | withDelimiter }}<span class="otcTable-unit">kWh</span>
              </td>
            </template>
            <td v-else class="otcTable-cell" colspan="4">約定なし</td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</template>

<script>
import groupBy from 'lodash/groupBy'
import filter from 'lodash/filter'

import ItemsByDay from './ItemsByDay.vue'
import ItemsByDayMixin from './mixins/ItemsByDay.js'

import ym from './functions/ym.js'
import withDelimiter from './functions/withDelimiter.js'

export default {
  components: { ItemsByDay },
  filters: {
    ym,
    withDelimiter,
  },
  mixins: [ItemsByDayMixin],
  /**
   * @typedef {Object.<number, Array>} Data
   * @property {Object[]} allItems - 先物取引情報のリスト
   * @property {number} index - 先物取引情報Arrayのうち表示するindex
   * @property {Object[]} codeNames - 原産品
   */
  data() {
    return {
      allItems: gon.futures || [],
      index: gon.futures ? Object.keys(gon.futures).length - 1 : -1,
      codeNames: gon.code_names,
    }
  },
  computed: {
    /**
     * 先物取引情報を原産品ごとにグループ化して出力
     * @returns {Object.<Array>}
     */
    groupedPositiveRows() {
      return groupBy(filter(this.theItems, 'volume'), 'code')
    },
    /**
     * テンプレート表示用に整形して出力
     * 原産品に約定なしの場合は約定なし表示行を追加
     * @returns {Object[]}
     */
    displayingRows() {
      return this.codeNames?.flatMap((codeName) => {
        if (this.isYakujouNashi(codeName.key)) {
          return {
            ...codeName,
            id: `${codeName.key}-empty`,
            row: null,
          }
        } else {
          return this.rowsOf(codeName.key).map((row) => {
            return {
              ...codeName,
              id: `${codeName.key}-${row.key}`,
              row,
            }
          })
        }
      })
    },
  },
  methods: {
    /**
     * 原産品コードごとの先物取引情報を返却
     * @param {number} code - 原産品コード
     * @returns {Array}
     */
    rowsOf(code) {
      return this.groupedPositiveRows[code]
    },
    /**
     * 原産品が約定なしである（表示行の中に存在しない）かを判定
     * @param {number} code - 原産品コード
     * @returns {boolean}
     */
    isYakujouNashi(code) {
      const rows = this.rowsOf(code)
      return !rows || !rows.length
    },
  },
}
</script>

<style lang="scss" scoped>
@import './css/vue_modules/table_section';
@import './css/vue_modules/content_heading';
@import './css/vue_modules/table_heading';
@import './css/vue_modules/otc_table';
@import './css/vue_modules/otc_table_futures';

.Futures {
  margin-top: 42px;
}
</style>
