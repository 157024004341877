import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

if (BUGSNAG_JS_API_KEY) {
  Bugsnag.start({
    apiKey: BUGSNAG_JS_API_KEY,
    plugins: [new BugsnagPluginVue()],
  })

  Bugsnag.getPlugin('vue').installVueErrorHandler(Vue)
}
