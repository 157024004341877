<template>
  <div class="dateSelector" :class="{ 'is-simple': isSimple }">
    <div class="dateSelector-arrowArea">
      <a
        href="#"
        class="dateSelector-selector"
        :class="{ 't-disabled': !prevDay }"
        @click.prevent="prev()"
      >
        <img src="~common/icon-arrow-left.svg" alt="前の日へ" class="dateSelector-arrow" />
      </a>
    </div>
    <div class="dateSelector-dateArea">
      <p class="dateSelector-upperText">
        <span class="dateSelector-dateText" v-text="theDay"></span>
        <span class="dateSelector-dateSuffix">受渡分</span>
      </p>
      <p class="dateSelector-lowerText">
        <span class="dateSelector-acquisitionDateText">{{ acquisitionDay }}</span> 10:00取得データ
      </p>
    </div>
    <div class="dateSelector-arrowArea">
      <a
        href="#"
        class="dateSelector-selector"
        :class="{ 't-disabled': !nextDay }"
        @click.prevent="next()"
      >
        <img
          src="~common/icon-arrow-left.svg"
          alt="次の日へ"
          class="dateSelector-arrow t-reverse"
        />
      </a>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import 'common/icon-arrow-left.svg'

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

export default {
  /**
   * @typedef {Object.<string, boolean>} Props
   * @property {string} theDay 現在表示している日付 (yyyy.mm.dd)
   * @property {string} [prevDay] 現在表示している日付の前日 (yyyy.mm.dd)
   * @property {string} [nextDay] 現在表示している日付の翌日 (yyyy.mm.dd)
   * @property {boolean} isSimple 日付とセレクタのみの表示とするか
   */
  props: {
    theDay: {
      type: String,
      required: false,
      default: undefined,
    },
    prevDay: {
      type: String,
      required: false,
      default: undefined,
    },
    nextDay: {
      type: String,
      required: false,
      default: undefined,
    },
    isSimple: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    acquisitionDay() {
      return (
        this.prevDay || dayjs(this.theDay, 'YYYY.MM.DD').subtract(1, 'day').format('YYYY.MM.DD')
      )
    },
  },
  methods: {
    /**
     * 前日のデータが存在する場合は prev イベントを親に通知
     */
    prev() {
      if (this.prevDay) this.$emit('prev')
    },
    /**
     * 翌日のデータが存在する場合は next イベントを親に通知
     */
    next() {
      if (this.nextDay) this.$emit('next')
    },
  },
}
</script>

<style lang="scss" scoped>
@use "./css/foundation/vars";

.dateSelector-dateArea {
  flex: 1 1 auto;
  padding: 0 4px;
}

.dateSelector-upperText {
  font-family: vars.$font-family-narrow;
  font-size: 1.8rem;
  font-weight: normal;
  text-align: center;
}

.dateSelector-lowerText {
  min-width: 14rem;
  font-family: vars.$font-family-narrow;
  font-size: 1.2rem;
  text-align: center;
  border-top: 1px solid rgba(#fff, 0.7);
}

.dateSelector-dateText {
  display: inline-block;
  min-width: 7.8rem;
  text-align: left;
}

.dateSelector-dateSuffix {
  font-size: 1.6rem;
  font-weight: bold;
}

.dateSelector-acquisitionDateText {
  display: inline-block;
  min-width: 5.2rem;
  text-align: left;
}

.dateSelector {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  &.is-simple {
    min-width: auto;

    .dateSelector-dateArea {
      padding: 0 2px;
    }

    .dateSelector-dateText {
      min-width: 8.6rem;
    }

    .dateSelector-upperText {
      font-size: 2rem;
    }

    .dateSelector-lowerText,
    .dateSelector-dateSuffix {
      display: none;
    }
  }
}

.dateSelector-arrowArea {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
}

.dateSelector-arrow {
  display: block;
  width: 12px;
  height: 20px;

  &.t-reverse {
    transform: scale(-1, 1);
  }
}

.dateSelector-selector {
  display: block;
  padding: 8px;
  cursor: pointer;

  &.t-disabled {
    pointer-events: none;
    cursor: default;

    .dateSelector-arrow {
      opacity: 0.15;
    }
  }
}
</style>
